<template>
	<Modal
		v-model="showModal"
		width="700"
	>
		<div class="modal-content">
			<TnHeading
				size="m"
				responsive
			>
				{{ modalInfo.heading }}
			</TnHeading>

			<TnParagraph
				v-if="modalInfo?.subHeading"
				size="m"
				responsive
			>
				{{ modalInfo?.subHeading }}
			</TnParagraph>

			<div class="modal-content--buttons">
				<Component
					v-for="(component, index) in resolvedComponent"
					:key="index"
					:is="component.component"
					v-bind="component.props"
					@click="component.action"
				/>
			</div>
		</div>
	</Modal>
</template>

<script setup lang="ts">
import type { ModalAction } from "~/components/platform/globals/Modal/Modal";

const { modalInfo, showModal } = useModal();

const resolvedComponent = computed(() => {
	return (modalInfo?.value?.actions ?? []).map((button: ModalAction) => {
		return {
			component: resolveComponent("TnButton"),
			props: button.props,
			action: button.action,
		};
	});
});
</script>

<style scoped lang="scss">
.modal-content {
	display: flex;
	flex-direction: column;
	gap: $spacing-m;
	&--buttons {
		display: flex;
		flex-direction: row;
		gap: $spacing-s;
	}
}
</style>
